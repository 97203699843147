.app-input {
    margin-bottom: 15px;

    select {
        margin-left: -4px;
        cursor: pointer;
    }

    &__wrapper {
        position: relative;
        padding: 8px 15px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid transparent;
        background-color: $c-white;

        &--revert {
            background-color: $c-body;
        }

        &--error {
            border-bottom: 1px solid $c-red;
        }

        &--select-without-label {
            .app-input__input {
                padding: 0;
            }
        }
    }

    &__label {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 17px;
        color: $c-brand-light-navy;
        font-size: $text-size-body;
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.2s;

        &--focus {
            top: 5px;
            color: $c-brand-navy;
            font-size: $text-size-xs;
        }
    }

    &__input {
        border: 0;
        padding: 16px 0 0;
        border-radius: 0;
        background-color: transparent;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &::placeholder {
            color: $c-brand-navy;
        }

        &:focus {
            box-shadow: none;
            background-color: transparent;
        }

        &:disabled {
            opacity: 0.5;
            background-color: transparent;
        }
    }

    &__icon {
        margin-left: 10px;

        &--select {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    &__text {
        margin: 4px 0 0;
        color: $c-gray;
        font-size: $text-size-xs;

        &--error {
            color: $c-red;
        }
    }
}
