.page-activate-plan-review {
    background-color: $c-white;

    .activate-plan-review {
        &__headline {
            margin-bottom: 35px;
            font-size: $text-size-m;

            @include max-width($scr-sm) {
                font-size: $text-size-xxl;
            }
        }

        .device-list {
            width: 100%;
            padding: 0;
            margin: 0;

            &__item {
                padding: 10px 0;
                list-style-type: none;
                border-bottom: 1px solid lighten($c-light-gray, 5%);
                display: flex;
                align-items: center;
            }

            &__image {
                width: 50px;
                height: 32px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &__edit-button {
                margin-left: auto;
                color: $c-brand-cyan;
                border: 0;
                background-color: transparent;
            }
        }

        .invoices {
            margin-top: 35px;

            &__text {
                margin: 10px 0 0;
                color: $c-brand-light-navy;
                font-size: $text-size-s;
            }

            &__attached {
                padding: 15px;
                margin: 10px 0;
                background-color: $c-body;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__delete-button {
                border: 0;
                background-color: transparent;
            }

            &__attach-button {
                min-width: 150px;
                margin: 15px 0;
                padding: 15px 20px;
                border: 0;
                text-decoration: none;
                white-space: nowrap;
                border: 1px solid $c-brand-navy;
                background-color: $c-white;
                cursor: pointer;
            }

            &__error {
                font-size: $text-size-xs;
                color: $c-red;
            }
        }

        .terms {
            margin: 30px 0 20px;
            padding: 20px;
            background-color: lighten($c-brand-cyan, 59%);

            &__headline {
                font-size: $text-size-m;
            }

            &__desc {
                margin-bottom: 8px;
                font-weight: $text-weight-medium;
            }

            &__small {
                margin-top: 4px;
                font-size: $text-size-s;

                a {
                    color: $c-brand-navy;
                    text-decoration: underline;
                }
            }
        }

        .program {
            min-height: 300px;
            padding: 20px;
            background-color: $c-body;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__image {
                margin-left: auto;
            }

            &__total {
                color: $c-brand-cyan;
                font-size: $text-size-xxl;
                font-weight: $text-weight-medium;
            }

            &__device-protected-icon {
                width: 20px;
                height: 20px;
            }

            &__total-devices {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            &__title {
                font-size: $text-size-l;
                font-weight: $text-weight-medium;
            }

            &__label {
                color: $c-brand-light-navy;
            }
        }

        &__text {
            margin: 0;
            line-height: 1.2;
        }

        &__row {
            padding: 15px 0;

            &--info {
                padding: 0 0 5px;
            }
        }

        &__col {
            &:last-child {
                text-align: right;
            }
        }

        &__button-wrapper {
            margin: 20px 0;
        }
    }
}
