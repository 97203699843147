.page-account-my-switch {
    background-color: $c-white;

    @include max-width($scr-md) {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .account-my-switch {
        &__container {
            @include max-width($scr-md) {
                padding: 0;
            }
        }

        &__wrapper {
            display: flex;

            @include max-width($scr-md) {
                flex-direction: column;
            }
        }

        .subscriptions {
            width: 100%;

            &__loading {
                height: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__wrapper {
                width: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
            }

            &__header {
                padding: 0 15px;
                display: flex;
                justify-content: space-between;

                @include max-width($scr-md) {
                    margin-bottom: 30px;
                    flex-direction: column;
                }
            }

            &__headline {
                font-size: $text-size-xxl;

                @include max-width($scr-md) {
                    margin: 15px 0;
                }
            }

            &__search-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px;
                border-bottom: 1px solid lighten($c-light-gray, 5%);

                @include max-width($scr-md) {
                    border-bottom: 1px solid lighten($c-light-gray, 5%);
                }

                input {
                    padding-right: 15px;
                    width: 100%;
                    border: 0;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        color: $c-brand-navy;
                        font-weight: $text-weight-light;
                    }
                }
            }

            &__device {
                padding: 20px 15px;
                list-style-type: none;
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    border-bottom: 1px solid lighten($c-light-gray, 5%);
                }

                &--selected {
                    background-color: $c-body;
                }

                .app-checkbox {
                    &__checker {
                        width: 20px;
                        height: 20px;
                        margin: 0;

                        &--active {
                            background-color: $c-brand-navy;

                            .app-checkbox__checked-icon {
                                color: $c-white;
                            }
                        }
                    }
                }
            }

            &__device-content {
                width: 100%;
                display: flex;
                align-items: center;

                @include max-width($scr-md) {
                    padding-left: 15px;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                }
            }

            &__device-image {
                width: 80px;
                height: 80px;
                margin: 0 15px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                flex-shrink: 0;
            }

            &__device-info {
            }

            &__expires-icon {
                margin-right: 15px;
            }

            &__device-header {
                display: flex;
                flex-wrap: wrap;
            }

            &__date,
            &__device-imei {
                color: lighten($c-brand-light-navy, 20%);
                font-size: $text-size-s;
            }

            &__device-status {
                color: $c-gray;
                font-size: $text-size-s;
                margin-right: 5px;

                &--active {
                    color: $c-brand-cyan;
                }

                &--submitted {
                    color: $c-warning;
                }

                &--cancelled {
                    color: $c-red;
                }
            }

            &__device-name {
                font-weight: $text-weight-medium;
            }

            &__swap-status {
                color: $c-brand-navy;
                font-size: $text-size-s;
                margin-right: 5px;
            }

            &__footer {
                margin: 30px 0;
                padding: 0 15px;
            }

            &__switch-button {
                .app-button {
                    @include max-width($scr-md) {
                        width: 100%;
                    }
                }
            }
        }

        &__text {
            margin: 0;
        }
    }
}
