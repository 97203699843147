.app-session-expired-modal {

    &__header {
        text-align: center;
        font-size: $text-size-l;
        font-weight: $text-weight-bold;
        padding: 15px;
    }

    &__header-text {
        margin-bottom: 0;
    }

    .modal-content {
        border: 0;
        border-radius: 0;
    }

    .content {
        padding: 15px;
        display: flex;
        justify-content: space-between;

        &__text {
            
        }

    }
}
