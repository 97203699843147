.app-button {
    display: inline-block;
    min-width: 150px;
    border: 0;
    padding: 15px 20px;
    text-decoration: none;
    background-color: $c-brand-yellow;

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        opacity: 0.3;
    }

    &__loading {
        margin-right: 10px;
    }

    span {
        color: $c-brand-navy;
        vertical-align: middle;
    }

    &__text {
        color: $c-brand-navy;
    }

    &__icon {
        margin-left: 20px;
        color: $c-brand-navy;
        font-size: $text-size-m;
    }
}
