.app-header {
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: $header-height;
    background-color: $c-white;
    border-bottom: 1px solid lighten($c-light-gray, 5%);
    z-index: 10;

    &__container {
        &--large {
            display: flex;
            height: 100%;
            align-items: center;
        }

        &--small {
        }
    }

    .branding {
        padding-right: 15px;

        @include max-width($scr-md) {
            flex: 1 0 auto;
        }

        &__link {
            display: block;
        }

        &__logo {
            display: block;
            width: 130px;
            height: 32px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../images/common/logo.svg);
        }
    }

    .navigation {
        &--large {
            padding-left: 30px;
            flex: 1 0 auto;

            @include max-width($scr-md) {
                display: none;
            }

            .navigation__list {
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none;
                border-right: 1px solid lighten($c-light-gray, 5%);
            }

            .navigation__item {
                margin: 0 20px;
                display: flex;
                align-items: center;

                &:last-child {
                    margin-left: auto;
                }
            }

            .navigation__sign-out {
                position: relative;
                height: $header-height;
                margin: 0;
                color: $c-brand-navy;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .navigation__link {
                position: relative;
                height: $header-height;
                text-decoration: none;
                color: $c-brand-navy;
                display: flex;
                justify-content: center;
                align-items: center;

                &.active {
                    pointer-events: none;

                    &::after {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        width: 100%;
                        border-bottom: 4px solid $c-brand-navy;
                    }
                }

                &:hover {
                    &::after {
                        position: absolute;
                        content: "";
                        bottom: 0;
                        width: 100%;
                        border-bottom: 4px solid $c-brand-navy;
                    }
                }
            }
        }

        &--small {
            .navigation__list {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            .navigation__item {
                margin: 10px 0;
            }

            .navigation__sign-out {
                position: relative;
                margin: 0;
                color: $c-brand-navy;
                font-size: $text-size-l;
                cursor: pointer;
            }

            .navigation__link {
                text-decoration: none;
                color: $c-brand-navy;
                font-size: $text-size-l;

                &.active {
                    pointer-events: none;
                }
            }
        }
    }

    .sign-in {
        padding: 0 15px;

        &__link {
            text-decoration: none;
            color: $c-brand-navy;
        }
    }

    .account {
        padding: 0 15px;
        height: $header-height;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: flex-end;

        &__link {
            color: $c-brand-navy;
            text-decoration: none;
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: flex-end;
        }

        &__name-initial {
            width: 40px;
            height: 40px;
            border-radius: 100px;
            background-color: lighten($c-light-gray, 10%);
            font-size: $text-size-s;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__customer-name {
            margin: 0 0 0 15px;
            line-height: 1.2;

            @include max-width($scr-md) {
                display: none;
            }
        }
    }

    .lang-switch {
        width: 75px;
        padding: 5px;
        background-color: lighten($c-light-gray, 10%);
        text-decoration: none;
        color: $c-brand-navy;
        font-size: $text-size-s;
        text-align: center;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;

        &__lang {
            padding: 0;
            border: 0;
            color: darken($c-light-gray, 10%);
            font-weight: $text-weight-medium;
            background-color: transparent;

            &:first-child {
                &:after {
                    content: "/";
                    margin: 0 8px;
                    color: darken($c-light-gray, 10%);
                    pointer-events: none;
                }
            }

            &--active {
                color: $c-brand-navy;
            }
        }
    }

    .mobile-nav-toggle {
        display: none;
        border: 0;
        padding: 0;
        background-color: transparent;
        color: $c-brand-navy;
        font-size: $text-size-xl;
        cursor: pointer;

        @include max-width($scr-md) {
            display: flex;
        }
    }

    .mobile-nav {
        display: none;
        position: fixed;
        top: $header-height;
        left: 0;
        padding: 20px;
        width: 100%;
        height: calc(100vh - #{$header-height});
        background-color: $c-white;
        z-index: 0;

        @include max-width($scr-md) {
            display: block;
        }
    }
}
