.page-cookie-policy {
    padding: 0 !important;
    background-color: $c-body;

    .cookie-policy {
        &__link {
            position: relative;
            padding: 20px 0;
            color: $c-gray;
            text-decoration: none;
            display: block;

            &.is-active {
                color: $c-brand-navy;
                font-weight: $text-weight-bold;

                &::after {
                    position: absolute;
                    content: "";
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    border-bottom: 4px solid $c-brand-navy;
                }
            }
        }

        .banner {
            padding: 30px 20px;
            height: 350px;
            width: 100%;
            background-color: $c-body;
            display: flex;
            align-items: center;

            @include max-width($scr-md) {
                height: auto;
            }

            &__content {
                display: flex;
                padding-right: 30px;
                width: 50%;
                height: 100%;
                flex-direction: column;
                justify-content: center;

                @include max-width($scr-md) {
                    padding-bottom: 0;
                    width: auto;
                }
            }

            &__headline {
                font-size: $text-size-xxxl * 1.2;
                font-weight: $text-weight-bold;

                @include max-width($scr-md) {
                    font-size: $text-size-xxxl * 0.9;
                }
            }
        }

        .content {
            padding: 30px 20px;
            background-color: $c-white;

            &__headline {
                margin: 0;
                font-size: $text-size-xxl;
                font-weight: $text-weight-bold;
            }
        }

        table {
            margin: 0;
            color: $c-brand-navy;

            td, th {
                @include max-width($scr-sm) {
                    word-break: break-word;
                }
            }
        }
    }
}
