.page-home {
    background-color: $c-white;

    @include min-width($scr-md) {
        padding-top: 0 !important;
    }

    .home {

        .banner {
            height: 500px;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right -60px bottom;
            background-image: url(../../images/home/hero-image.png);

            @include max-width($scr-md) {
                height: auto;
                padding-bottom: 345px;
                background-size: 380px auto;
            }

            &__content {
                display: flex;
                padding-right: 30px;
                padding-bottom: 80px;
                width: 50%;
                height: 100%;
                flex-direction: column;
                justify-content: center;

                @include max-width($scr-md) {
                    padding-bottom: 0;
                    width: auto;
                }

            }

            &__headline {
                font-size: $text-size-xxxl * 1.2;
                font-weight: $text-weight-bold;

                @include max-width($scr-md) {
                    font-size: $text-size-xxxl * 0.9;
                }

            }

            &__lead {
                margin-bottom: 10px;
                font-size: $text-size-l;
            }

            &__list {
                padding-left: 20px;
            }

            &__list-item {
                font-size: $text-size-body;
                color: $c-brand-light-navy;
            }

        }

        .features {
            display: flex;
            margin-top: -80px;
            margin-bottom: 50px;
            justify-content: space-between;

            @include max-width($scr-md) {
                display: block;
                margin-top: 0;
                margin-bottom: 0;
            }

            &__card {
                padding: 30px;
                width: calc(100% / 3 - 20px);
                flex: 0 1 auto;
                background-color: lighten($c-light-gray, 10%);

                @include max-width($scr-md) {
                    margin-bottom: 30px;
                    width: auto;
                }

            }

            &__header {
                font-size: $text-size-xl;
                font-weight: $text-weight-bold;
                line-height: 1em;
            }

            &__text {
                font-size: $text-size-body;
            }

        }

    }

}
