.page-about {
    background-color: $c-white;
    padding: 0 !important;

    .about {
        padding-bottom: 100px;

        &__description {
            width: 70%;
            padding: 100px 0;
            margin: 0 auto;
            font-weight: $text-weight-thin;
            font-size: $text-size-xxl * 1.3;

            @include max-width($scr-md) {
                width: 100%;
                font-size: $text-size-xl;
            }
        }

        &__accordion {
            &.is-active {
                .about__plus-icon {
                    transform: rotate(45deg);
                }

                .faq,
                .eligibility {
                    display: block;
                }
            }
        }

        &__toggle-button {
            width: 100%;
            padding: 30px 0;
            border: 0;
            border-bottom: 1px solid darken($c-body, 5%);
            line-height: 1;
            text-align: left;
            background-color: $c-white;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__label {
            margin: 0;
            text-align: left;
            font-size: $text-size-xl;
            font-weight: $text-weight-bold;
        }

        .banner {
            &__container {
                position: relative;
                height: 500px;
                width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right -60px bottom;
                background-image: url(../../images/about/hero-image.png);

                @include max-width($scr-md) {
                    padding: 15px;
                    background-size: cover;
                    background-position: center;
                    max-width: 100%;
                }

                &::before {
                    position: absolute;
                    content: "";
                    width: 50px;
                    height: 50px;
                    right: 50px;
                    bottom: 50px;
                    background-color: $c-white;
                    display: block;

                    @include max-width($scr-md) {
                        width: 35px;
                        height: 35px;
                        right: 50px;
                        bottom: 150px;
                    }
                }

                &::after {
                    position: absolute;
                    content: "";
                    width: 300px;
                    height: 45px;
                    right: 300px;
                    bottom: 170px;
                    background-color: $c-brand-cyan;
                    display: block;

                    @include max-width($scr-md) {
                        width: 185px;
                        height: 35px;
                        bottom: 120px;
                        left: -15px;
                    }

                    @include max-width($scr-xs) {
                        bottom: 90px;
                    }
                }
            }

            &__content {
                padding-right: 30px;
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include max-width($scr-md) {
                    padding-bottom: 0;
                    width: auto;
                }
            }

            &__headline {
                margin-bottom: 45px;
                font-size: $text-size-xxxl * 1.2;
                font-weight: $text-weight-bold;

                @include max-width($scr-md) {
                    position: relative;
                    color: $c-white;
                    font-size: $text-size-xxl * 1.2;
                }
            }

            &__button {
                @include max-width($scr-md) {
                    position: absolute;
                    bottom: -25px;
                }
            }
        }

        .usp {
            padding-bottom: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &__item {
                width: 47%;
                margin-bottom: 50px;
                display: flex;
                align-items: center;

                @include max-width($scr-md) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            &__icon {
                width: 85px;
                margin-right: 20px;

                @include max-width($scr-md) {
                    width: 75px;
                }
            }

            &__text {
                margin: 0;
                font-size: $text-size-xxl;
                font-weight: $text-weight-medium;

                @include max-width($scr-md) {
                    font-size: $text-size-l;
                    font-weight: $text-weight-normal;
                }
            }
        }

        .activate {
            background-color: $c-body;

            &__container {
                position: relative;
                height: 500px;
                padding-top: 100px;
                display: flex;

                @include max-width($scr-md) {
                    height: auto;
                    max-width: 100%;
                    flex-direction: column;
                }
            }

            &__content {
                padding-right: 30px;
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;

                @include max-width($scr-md) {
                    width: 100%;
                }
            }

            &__headline {
                font-size: $text-size-xxl;
                font-weight: $text-weight-bold;

                @include max-width($scr-md) {
                    font-size: $text-size-xl;
                }
            }

            &__description {
                font-size: $text-size-l;
            }

            &__image {
                width: 50%;
                min-width: 300px;
                height: 500px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: right bottom;
                background-image: url(../../images/about/activate.png);

                @include max-width($scr-md) {
                    margin-top: 50px;
                    margin-bottom: -100px;
                    margin-right: -15px;
                    width: 80%;
                    height: 400px;
                    margin-left: auto;
                    background-position: center;
                }
            }

            &__button {
                margin-top: 30px;
            }
        }

        .switch {
            padding-top: 200px;
            padding-bottom: 100px;
            overflow: hidden;

            &__headline {
                text-align: center;
                font-weight: $text-weight-thin;
                font-size: $text-size-xxxl;
            }

            &__content {
                width: 100%;
                display: flex;
                align-items: center;

                @include max-width($scr-md) {
                    flex-direction: column;
                }
            }

            &__image {
                width: 65%;
                min-width: 500px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 120%;
                }
            }

            &__list {
                width: 35%;
                margin-left: auto;

                @include max-width($scr-md) {
                    width: 100%;
                    margin: 0 auto;
                }
            }

            &__item {
                padding: 20px 0;
                display: flex;
                align-items: center;
                border-top: 1px solid $c-brand-navy;
                img {
                    width: 45px;
                }
            }

            &__text {
                margin: 0 15px;
                font-size: $text-size-l;

                &--lighten {
                    color: lighten($c-brand-light-navy, 20%);
                }
            }
        }

        .faq {
            display: none;

            &__accordion {
                &.is-active {
                    .faq__content {
                        display: block;
                    }

                    .faq__plus-icon {
                        transform: rotate(45deg);
                    }
                }
            }

            &__toggle-button {
                width: 100%;
                padding: 20px 0;
                border: 0;
                border-bottom: 1px solid darken($c-body, 5%);
                line-height: 1;
                background-color: $c-white;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__content {
                display: none;
                padding-bottom: 20px;
            }

            &__description {
                margin: 10px 0;
                color: $c-brand-light-navy;
            }
        }

        .eligibility {
            display: none;
            background-color: $c-white;

            .search-input {
                padding: 25px 0;
                width: 100%;
                max-width: 300px;

                @include max-width($scr-md) {
                    max-width: 100%;
                }

                &__label {
                    font-size: $text-size-m;
                }

                &__input {
                    width: 100%;
                    padding: 10px;
                    border: 0;
                    outline: none;
                    box-shadow: none;
                }

                &__wrapper {
                    position: relative;
                }

                &__inner-wrapper {
                    display: flex;
                    align-items: center;
                    border: 1px solid darken($c-body, 5%);
                }

                &__clear-button {
                    border: 0;
                    background-color: $c-white;
                }

                &__recommended-list {
                    position: absolute;
                    top: 100%;
                    width: 100%;
                    max-height: 400px;
                    overflow-y: scroll;
                    padding: 0;
                    z-index: 10;
                }

                &__recommended-item {
                    padding: 10px;
                    border: 1px solid darken($c-body, 5%);
                    border-top: 0;
                    cursor: pointer;
                    list-style: none;
                    background-color: $c-body;
                }
            }

            &__loading {
                height: 300px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__title {
                padding: 0 0 25px;
                border-bottom: 1px solid darken($c-body, 5%);
            }

            &__accordion {
                &.is-active {
                    .eligibility__content {
                        display: block;
                    }

                    .eligibility__plus-icon {
                        transform: rotate(45deg);
                    }
                }
            }

            &__toggle-button {
                width: 100%;
                padding: 20px 0;
                border: 0;
                border-bottom: 1px solid darken($c-body, 5%);
                line-height: 1;
                text-align: left;
                background-color: $c-white;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__content {
                display: none;
                padding-bottom: 20px;
            }

            &__description {
                margin: 10px 0;
                font-size: $text-size-m;
                font-weight: $text-weight-medium;
            }

            &__device {
                margin: 0 0 5px;
                font-size: $text-size-s;
                color: $c-brand-light-navy;

                &--selected {
                    color: $c-brand-cyan;
                    font-weight: $text-weight-medium;
                }
            }
        }
    }
}
