.app-otp-modal {
    
    .modal-content {
        padding: 35px 25px;
        border: 0;
        border-radius: 0;
        background-color: $c-body;
    }

    &__text {
        font-size: $text-size-l;
    }

    &__form {
        display: flex;
        align-items: flex-start;

        @include max-width($scr-sm) {
            flex-direction: column;
        }
        
        .app-input {
            width: 100%;
            margin: 0 20px 0 0;

            @include max-width($scr-sm) {
                margin: 0 0 15px;
            }
        }
    }
}
