.app-pagination {
    padding: 20px 0;
    display: flex;
    justify-content: center;

    .pagination {
        margin: 0;

        &__item {
            &--active {
                .pagination__button {
                    color: $c-white;
                    background-color: $c-brand-navy;
                }
            }

            &--prev {
                margin-right: 15px;
            }

            &--next {
                margin-left: 15px;
            }
        }

        &__button {
            width: 40px;
            height: 40px;
            margin-right: 1px;
            font-size: $text-size-xs;
            background-color: darken($c-body, 5%);
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                background-color: $c-body;
            }
        }
    }
}
