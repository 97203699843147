.page-account-profile {
    background-color: $c-white;

    @include max-width($scr-md) {
        padding: 0 0 30px !important;
    }

    .account-profile {
        &__container {
            @include max-width($scr-md) {
                padding: 0;
            }
        }

        &__wrapper {
            display: flex;

            @include max-width($scr-md) {
                flex-direction: column;
            }
        }

        &__form {
        }

        &__category {
            margin: 40px 0 20px;
            font-size: $text-size-m;

            &:first-child {
                @include min-width($scr-md) {
                    margin-top: 0;
                }
            }
        }

        &__button-wrapper {
            margin-top: 50px;
        }
    }
}
