.page-switch-select-device {
    background-color: $c-white;

    @include max-width($scr-md) {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .switch-select-device {
        &__container {
            @include max-width($scr-md) {
                padding: 0;
            }
        }

        &__wrapper {
            display: flex;

            @include max-width($scr-md) {
                flex-direction: column;
            }
        }

        .switches {
            width: 100%;

            &__header {
                padding: 0 15px;
                display: flex;
                justify-content: space-between;

                @include max-width($scr-md) {
                    margin-bottom: 30px;
                    flex-direction: column;
                }
            }

            &__headline {
                font-size: $text-size-xxl;

                @include max-width($scr-md) {
                    margin: 15px 0;
                }
            }

            &__search-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px;
                border-bottom: 1px solid lighten($c-light-gray, 5%);

                @include max-width($scr-md) {
                    border-bottom: 1px solid lighten($c-light-gray, 5%);
                }

                input {
                    padding-right: 15px;
                    width: 100%;
                    border: 0;

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        color: $c-brand-navy;
                        font-weight: $text-weight-light;
                    }
                }
            }

            &__device-list {
                width: 100%;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
            }

            &__device {
                padding: 20px 15px;
                list-style-type: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $c-light-gray;

                @include max-width($scr-md) {
                    flex-direction: column;
                }
            }

            &__row {
                width: 75%;
                display: flex;
                justify-content: space-between;

                @include min-width($scr-md) {
                    align-items: center;
                }

                @include max-width($scr-md) {
                    width: 100%;
                }

                &:last-child {
                    width: 20%;

                    @include max-width($scr-md) {
                        margin-top: 15px;
                        width: 100%;
                    }
                }
            }

            &__col {
                display: flex;
                align-items: center;

                @include max-width($scr-md) {
                    flex-direction: column;
                    justify-content: center;
                }

                &--info {
                    @include max-width($scr-md) {
                        flex: 1;
                        padding: 15px;
                        background-color: $c-body;
                    }
                }

                &--selection {
                    flex: 1;

                    @include max-width($scr-md) {
                        padding: 15px;
                        border: 1px solid $c-light-gray;
                    }
                }
            }

            &__arrow-icon {
                width: 30px;
                margin: 0 25px;

                img {
                    width: 100%;
                }

                @include max-width($scr-md) {
                    margin: 0 10px;
                }
            }

            &__image {
                width: 40px;
                height: 40px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                @include min-width($scr-md) {
                    margin-right: 10px;
                }
            }

            &__default-image {
                height: 40px;
                min-width: 40px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(../../../images/switch/default-switch.svg);
            }

            &__info {
                @include min-width($scr-md) {
                    width: 200px;
                }

                @include max-width($scr-md) {
                    text-align: center;
                }
            }

            &__label,
            &__imei {
                color: lighten($c-brand-light-navy, 20%);
                font-size: $text-size-s;

                @include max-width($scr-md) {
                    font-size: $text-size-xs;
                }
            }

            &__name {
                @include min-width($scr-md) {
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                @include max-width($scr-md) {
                    font-size: $text-size-s;
                }
            }

            &__dropdown {
                border: 0;
                box-shadow: none;
                white-space: pre;
                text-overflow: ellipsis;
                cursor: pointer;

                @include max-width($scr-md) {
                    text-align: center;
                    font-size: $text-size-s;
                }
            }

            &__delete-button {
                border: 0;
                background-color: transparent;
                font-size: $text-size-m;
            }

            &__footer {
                margin: 30px 0;
                padding: 0 15px;
            }

            &__switch-button {
                .app-button {
                    @include max-width($scr-md) {
                        width: 100%;
                    }
                }
            }
        }

        &__text {
            margin: 0;
        }
    }
}
