.app-alert-modal {
    .modal-content {
        border: 0;
        border-radius: 0;
    }

    .content {
        padding: 15px;
        display: flex;
        justify-content: space-between;

        &__text {
        }

        &__close-button {
            cursor: pointer;
            font-size: $text-size-l;
        }
    }
}
