.app-side-nav {
    &__wrapper {
        width: 300px;

        @include max-width($scr-md) {
            width: 100%;
            padding: 0 10px;
            background-color: $c-body;
        }
    }

    &__toggle {
        display: none;
        width: 100%;
        border: 0;
        padding: 15px 0;
        text-align: left;
        background-color: $c-body;
        align-items: center;
        justify-content: space-between;

        @include max-width($scr-md) {
            display: flex;
        }
    }

    &__lists {
        margin: 0;
        padding-left: 0;
        padding-right: 50px;
        border-bottom: 1px solid lighten($c-light-gray, 5%);
        
        @include max-width($scr-md) {
            display: none;
        }

        &--active {
            display: block;
        }
    }

    &__item {
        margin: 10px 0;
        list-style-type: none;

        @include max-width($scr-md) {
            padding: 0;
            border-bottom: 0;
        }

        &--profile {
            padding: 20px 0;
            display: flex;
            align-items: center;

            @include max-width($scr-md) {
                display: none;
            }
        }
    }

    &__name-initial {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        margin-right: 15px;
        background-color: lighten($c-light-gray, 10%);
        font-size: $text-size-s;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__category {
        margin-bottom: 10px;
        color: lighten($c-brand-light-navy, 30%);
        font-size: $text-size-s;

        @include max-width($scr-md) {
            display: none;
        }
    }

    &__text {
        margin: 0;
        padding: 2.5px 0;
        cursor: pointer;

        @include max-width($scr-md) {
            padding: 10px 0;
        }

        &--bold {
            font-weight: $text-weight-medium;
        }
    }
}
