/* -- Component -- */
$header-height: 70px;

/* -- Color -- */
$c-white: #FFFFFF;
$c-black: #000000;
$c-body: #F3F3F6;
$c-gray: #999999;
$c-dark-gray: #666666;
$c-light-gray: #DDDDDD;
$c-brand-navy: #170F4F;
$c-brand-yellow: #E3D900;
$c-brand-light-navy: #746F95;
$c-brand-cyan: #00BAC7;
$c-red: #B80000;
$c-warning: #E3D900;
$c-green: #28A745;

/* -- Font size -- */
$text-size-xxxl: 48px;
$text-size-xxl: 32px;
$text-size-xl: 28px;
$text-size-l: 22px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-bold: 700;
$text-weight-medium: 500;
$text-weight-normal: 400;
$text-weight-light: 300;
$text-weight-thin: 100;

/* -- Media query -- */
$scr-lg: "1199px";
$scr-md: "991px";
$scr-sm: "767px";
$scr-xs: "679px";
