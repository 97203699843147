.app-checkbox {
    &__wrapper {
        padding: 10px 0;
        display: flex;
    }

    &__checker {
        width: 25px;
        height: 25px;
        padding: 0;
        margin-right: 10px;
        font-size: $text-size-l;
        border: 2px solid $c-brand-navy;
        background-color: $c-white;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        &--active {
        }

        &--disabled {
            opacity: 0.1;
        }
    }

    &__label {
        margin-top: 2.5px;
        margin-bottom: 0;
        font-size: $text-size-s;
    }

    &__text {
        margin: 0;
        color: $c-gray;
        font-size: $text-size-xs;

        &--error {
            color: $c-red;
        }
    }
}
