.page-account-create {

    .account-create {
        padding: 50px 0;

        &__headline {
            font-size: $text-size-xxl;
        }

        &__category {
            margin: 40px 0 20px;
            font-size: $text-size-m;
        }

        &__description {
        }

        &__button-wrapper {
            margin-top: 15px;
        }

        &__sign-in {
            margin: 15px 0 0;
            font-size: $text-size-s;
        }

        &__link { 
            color: $c-brand-navy;
            font-weight: $text-weight-bold;
        }

        .app-checkbox {
            margin-top: 40px;
        }
    }

}