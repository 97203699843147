.page-account-backup-recovery-android {
    background-color: $c-white;

    @include max-width($scr-md) {
        padding: 0 !important;
    }

    .backup-recovery-android {
        &__container {
            @include max-width($scr-md) {
                padding: 0;
            }
        }

        &__wrapper {
            display: flex;

            @include max-width($scr-md) {
                flex-direction: column;
            }
        }

        &__content {
            padding: 20px;
            width: 100%;

            .service {
                padding: 55px 0 25px;
                margin-bottom: 25px;
                border-bottom: 1px solid lighten($c-light-gray, 5%);
                display: flex;
                align-items: center;

                @include max-width($scr-lg) {
                    align-items: flex-start;
                    flex-direction: column;
                }

                &__img {
                    width: 60px;
                    margin-right: 25px;

                    @include max-width($scr-lg) {
                        margin-bottom: 10px;
                    }
                }

                &__content {
                }

                &__note {
                    margin: 0 0 5px;
                    color: $c-brand-light-navy;
                }

                &__title {
                    margin: 0;
                    font-size: $text-size-l;
                }
            }

            .tutorial {
                display: flex;
                justify-content: space-between;

                @include max-width($scr-lg) {
                    flex-direction: column;
                }

                &__header {
                    @include min-width($scr-lg) {
                        min-height: 130px;
                    }
                }

                &__step {
                    padding: 15px;
                    width: 30%;

                    @include max-width($scr-lg) {
                        width: 100%;
                        margin-bottom: 25px;

                        &:not(:last-child) {
                            border-bottom: 1px solid lighten($c-light-gray, 5%);
                        }
                    }

                    @include min-width($scr-lg) {
                        &:nth-child(2),
                        &:nth-child(3) {
                            .tutorial__img {
                                margin-top: 58px;
                            }
                        }
                    }
                }

                &__img {
                    width: 100%;
                    max-width: 200px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    img {
                        width: 100%;
                        image-rendering: -moz-crisp-edges; /* Firefox */
                        image-rendering: -o-crisp-edges; /* Opera */
                        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
                        image-rendering: crisp-edges;
                        -ms-interpolation-mode: nearest-neighbor;
                    }
                }

                &__title {
                    font-size: $text-size-m;
                }

                &__settings {
                    padding: 45px 0 0;
                }

                &__setting-step {
                    margin-bottom: 10px;
                    font-size: $text-size-s;
                    list-style: none;
                    display: flex;
                }

                &__badge {
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;

                    span {
                        height: 25px;
                        width: 25px;
                        border-radius: 25px;
                        background-color: $c-brand-yellow;
                        font-size: $text-size-xs;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        &__title {
            margin: 0;
            padding: 20px 0;
            font-size: $text-size-xl;
            border-bottom: 1px solid lighten($c-light-gray, 5%);
        }

        &__tabs {
            padding: 0;
            margin-top: 30px;
            border: 0;
        }

        &__tab {
            width: 220px;
            cursor: pointer;

            @include max-width($scr-md) {
                width: 50%;
            }

            .nav-link {
                padding: 15px 20px;
                border: 0;
                color: $c-brand-navy;
                background-color: $c-body;
            }

            .active {
                color: $c-white;
                background-color: $c-brand-navy;
            }

            &:first-child {
                .nav-link {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
            }

            &:last-child {
                .nav-link {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
