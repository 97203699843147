.page-account-sign-in {

    .account-sign-in {
        padding: 50px 0;

        &__headline {
            font-size: $text-size-xxl;
        }

        &__sub-headline {
            margin-bottom: 25px;
            font-size: $text-size-l;
        }

        &__button-wrapper {
            margin-top: 15px;
        }

        &__text {
            margin: 15px 0 0;
            font-size: $text-size-s;
        }

        &__link { 
            color: $c-brand-navy;
            font-weight: $text-weight-bold;
        }

        &__captcha {
            margin-bottom: 5px;
        }

        &__switch {
            padding: 0;
            border: 0;
            font-size: $text-size-s;
            font-weight: $text-weight-bold;
            text-decoration: underline;
            background-color: transparent;
        }
    }

}