html,
body {
    height: 100%;
}

body {
    color: $c-brand-navy;
    font-size: $text-size-body;
    font-family: "Roboto", sans-serif;
    background-color: $c-body;
}
