.page-switch-delivery-details {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: $c-white;

    @include max-width($scr-md) {
        padding: 0 !important;
    }

    .switch-delivery-details {
        &__container {
            position: relative;

            @include min-width($scr-md) {
                padding-left: 35px;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 1px;
                    background-color: $c-brand-navy;
                }
            }
        }

        &__row {
            padding-top: 35px;
            display: flex;
            justify-content: space-between;

            &:last-child {
                padding: 35px 0;
            }

            @include max-width($scr-md) {
                flex-direction: column-reverse;
            }
        }

        &__col {
            width: 100%;

            &--shipping {
                @include min-width($scr-md) {
                    margin-right: 8%;
                }

                @include max-width($scr-md) {
                    margin-top: 25px;
                }
            }

            &--plan {
                @include min-width($scr-md) {
                    width: 500px;
                }
            }
        }

        &__text {
            margin: 0;
        }

        &__headline {
            position: relative;
            font-size: $text-size-l;
            font-weight: $text-weight-normal;
            display: flex;
            align-items: center;

            &--deco {
                @include min-width($scr-md) {
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: -45px;
                        width: 20px;
                        height: 20px;
                        border-radius: 20px;
                        border: 2px solid $c-brand-navy;
                        background-color: $c-white;
                    }
                }
            }
        }

        &__terms {
            ul {
                padding-left: 20px;
            }
        }

        &__button-wrapper {
            margin-top: 25px;
        }

        .shipping-info {
            padding: 20px;
            background-color: $c-body;

            &__header {
                padding: 0 0 20px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid lighten($c-light-gray, 5%);
            }

            &__headline {
                margin-left: 15px;
                font-size: $text-size-l * 0.95;
                font-weight: $text-weight-medium;
            }

            &__device-list {
                padding: 0;
            }

            &__device-item {
                padding: 20px 0;
                border-bottom: 1px solid lighten($c-light-gray, 5%);
                list-style-type: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__device {
                padding-right: 15px;
                display: flex;
                align-items: center;
                flex: 2;
            }

            &__address {
                padding-right: 15px;
                flex: 1;
            }

            &__device-image {
                width: 40px;
                height: 40px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                flex-shrink: 0;
            }

            &__label {
                color: lighten($c-brand-light-navy, 20%);
                font-size: $text-size-s;

                @include max-width($scr-md) {
                    font-size: $text-size-xs;
                }
            }

            &__company-name {
                font-size: $text-size-s;
                font-weight: $text-weight-medium;
            }

            &__address-name {
                font-size: $text-size-s;
                color: $c-brand-light-navy;
            }

            &__edit-button {
                flex: 0.3;
                text-align: right;

                button {
                    border: 0;
                    background-color: transparent;
                }
            }
        }

        .plan-details {
            padding: 20px;
            background-color: $c-body;

            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__headline {
                font-size: $text-size-l * 0.95;
                font-weight: $text-weight-medium;
            }

            &__lists {
                padding: 0;
                margin-top: 25px;
            }

            &__item {
                list-style-type: none;
                color: $c-brand-light-navy;
            }

            &__price {
                display: flex;
                justify-content: space-between;

                span {
                    color: $c-brand-navy;
                }
            }

            &__total {
                margin: 25px 0 0;
                font-size: $text-size-xl;
                font-weight: $text-weight-medium;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
