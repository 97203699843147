.app-delivery-address-modal {
    @include min-width($scr-md) {
        max-width: 768px !important;
    }

    .modal-content {
        position: relative;
        padding: 35px 25px;
        border: 0;
        border-radius: 0;

        @include min-width($scr-md) {
            min-height: 50vh;
        }
    }

    .delivery-address-modal {
        flex: 1;
        display: flex;
        flex-direction: column;

        &__close-button {
            position: absolute;
            top: 7.5px;
            right: 10px;
            border: 0;
            background-color: transparent;
        }

        &__text {
            margin: 0;
        }

        &__headline {
            font-size: $text-size-l;
        }

        &__label {
            margin: 0;
        }

        &__name {
            margin-bottom: 15px;
            font-weight: $text-weight-medium;
        }
        &__contact {
            margin-bottom: 15px;
            color: $c-brand-light-navy;
        }

        &__address {
            color: $c-brand-light-navy;
        }

        &__form {
            margin-top: 25px;
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        &__address-wrapper {
            margin-top: 25px;
        }

        &__content {
            margin-right: 40px;
        }

        &__radio-wrapper {
            display: flex;

            .app-input {
                &__wrapper {
                    padding: 0;

                    input {
                        position: relative;
                        margin-left: auto;
                        margin-right: 10px;
                    }
                }
            }
        }

        &__input-group {
            display: flex;

            @include max-width($scr-md) {
                flex-direction: column;
            }
        }

        &__input-wrapper {
            margin-bottom: 25px;
            display: flex;
            flex-wrap: wrap;

            .app-input {
                width: calc(50% - 15px);
                margin: 15px 15px 0 0;

                @include max-width($scr-md) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }

        &__button-wrapper {
            margin-top: auto;
        }
    }
}
