.page-activate-plan {
    background-color: $c-white;

    .activate-plan {
        &__headline {
            margin-bottom: 35px;
            font-size: $text-size-m;
        }

        &__labels {
            padding: 0 10px;
            display: flex;

            @include max-width($scr-md) {
                display: none;
            }
        }

        &__text {
            margin: 10px 0;
            padding: 0 10px;
            font-size: $text-size-s;
            width: 25%;

            &:nth-last-child(2) {
                width: calc(50% - 38px);
            }

            &:last-child {
                width: 38px;
            }
        }

        &__form {
        }

        &__inputs-accordion {
            background-color: $c-body;

            @include max-width($scr-md) {
                border-bottom: 1px solid $c-white;
            }

            &.is-active {
                .activate-plan__input-wrapper {
                    @include max-width($scr-md) {
                        display: flex;
                    }
                }

                .activate-plan__accordion-chevron {
                    transform: rotate(-180deg);
                }
            }
        }

        &__accordion-toggle {
            padding: 15px;
            font-weight: $text-weight-medium;
            cursor: pointer;
            display: none;
            justify-content: space-between;
            align-items: center;

            @include max-width($scr-md) {
                display: flex;
            }
        }

        &__input-wrapper {
            display: flex;
            padding: 15px 10px 0;

            @include max-width($scr-md) {
                padding-top: 0;
                display: none;
                flex-direction: column;
            }
        }

        &__input-group {
            position: relative;
            margin: 0 5px;
            width: 25%;
            border: 1px solid transparent;
            background-color: $c-white;

            @include max-width($scr-md) {
                width: 100%;
                margin: 0 0 15px;
            }

            .app-input {
                margin-bottom: 0;

                &__label {
                    display: none;

                    @include max-width($scr-md) {
                        display: block;
                    }
                }

                &__input {
                    @include min-width($scr-md) {
                        padding: 0;
                    }

                    @include max-width($scr-md) {
                        &::placeholder {
                            color: transparent;
                        }
                    }
                }
            }
        }

        &__select-devices {
            @include min-width($scr-md) {
                width: 50%;
            }
        }

        &__input-delete {
            padding-left: 5px;
            font-size: $text-size-m;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            @include max-width($scr-md) {
                border: 1px solid $c-brand-navy;
            }
        }

        &__delete-button {
            border: 0;
            background-color: transparent;

            @include max-width($scr-md) {
                width: 100%;
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            span {
                line-height: 1;
                margin-right: 10px;
                font-size: $text-size-s;
                display: none;

                @include max-width($scr-md) {
                    display: block;
                }
            }
        }

        .add-device-wrapper {
            padding: 15px 10px 15px;
            margin-bottom: 35px;
            background-color: $c-body;
            display: flex;

            @include max-width($scr-md) {
                justify-content: center;
            }

            &__decoration {
                width: calc(25% - 15px);
                margin: 0 5px;
                background-color: darken($c-body, 2%);

                @include max-width($scr-md) {
                    display: none;
                }
            }

            &__decoration-wrapper {
                display: flex;
                width: calc(50% + 15px);

                @include max-width($scr-md) {
                    width: 100%;
                }

                .add-device-wrapper__decoration {
                    width: 100%;
                }
            }

            &__button {
                padding: 15px;
                margin-left: 5px;
                font-size: $text-size-s;
                border: 1px solid $c-brand-navy;
                background-color: transparent;
                white-space: nowrap;

                @include max-width($scr-md) {
                    width: 100%;
                    margin-left: 0;
                }

                span {
                    margin-right: 10px;
                }

                &:disabled {
                    border: 1px solid darken($c-body, 5%);
                }
            }
        }

        .eligibility {
            margin-top: 30px;
        }
    }
}
