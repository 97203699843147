.app-footer {
    padding: 50px 0 25px;
    background-color: $c-brand-navy;
    flex-shrink: 0;

    &__divider {
        width: 100%;
        margin: 35px 0;
        border-bottom: 1px solid $c-brand-light-navy;
    }

    &__container {
    }

    &__branding {
        margin-bottom: 30px;
    }

    &__branding-link {
        display: block;
        color: $c-white;
        font-size: $text-size-s;
        text-decoration: none;

        &:hover {
            color: $c-white;
        }

    }

    &__branding-logo {
        display: block;
        width: 130px;
        height: 32px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../../images/common/white-logo.svg);
        cursor: pointer;
    }

    &__section {
        margin: 0;
        color: $c-brand-cyan;
        font-size: $text-size-s;
    }

    &__nav-list {
        height: 100%;
        margin: 0 0 20px 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }

    &__nav-item {
        margin: 0 20px 0 0;
        color: $c-white;
        font-size: $text-size-s;
        align-items: center;
    }

    &__nav-link {
        color: $c-white;
        text-decoration: none;

        &.active {
            pointer-events: none;
        }

        &:hover {
            color: $c-white;
        }

    }

    &__copyright {
        width: 65%;
        color: $c-brand-light-navy;
        display: block;

        @include max-width($scr-md) {
            width: 100%;
        }
    }

}
