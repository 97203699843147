.page-activate-plan-success {
    height: 500px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    background-image: url(../../../images/activate-plan/success-bg.jpg);
    background-color: $c-white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include max-width($scr-md) {
        background-position: calc(100% + 1.5rem) top;
    }

    .activate-plan-success {

        .banner {
            height: 100%;
            max-width: 768px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            @include max-width($scr-sm) {
                justify-content: flex-start;
            }

            &__headline {
                margin-bottom: 35px;
                color: $c-white;
                font-size: $text-size-xxxl;

                @include max-width($scr-sm) {
                    font-size: $text-size-xxl;
                }
            }

            &__sub-headline {
                color: $c-white;
            }

            &__link {
                color: $c-white;
            }
        }
    }
}
