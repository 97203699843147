// app-view
.app-view {
    display: flex;
    flex-direction: column;
    padding-top: $header-height;
    height: 100vh;
    min-width: 320px;
}

// app-main
.app-main {
    flex-grow: 1;
}

// app-page
.app-page {
    height: 100%;
    padding: 30px 20px;

    a {
        word-break: break-word;
    }
}

.app-spinner {
    color: $c-brand-yellow;
}