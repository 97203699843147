.page-switch-payment-details {
    padding: 0 !important;

    .switch-payment-details {
        &__container {
        }

        &__header {
        }

        &__headline {
            font-size: $text-size-l;
        }

        &__text {
            margin-top: 25px;
            font-size: $text-size-m;
            font-weight: $text-weight-light;
            display: flex;

            &--price {
                margin-top: 0;
                justify-content: space-between;
            }

            &--card-info {
                &:after {
                    content: "";
                    display: block;
                    width: 80px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url(../../../images/switch/master-visa-icon.svg);
                }
            }
        }

        &__form {
            width: 100%;
            max-width: 450px;

            .app-button {
                background-color: $c-brand-navy;

                span {
                    color: $c-white;
                }

                &__icon {
                    color: $c-brand-cyan;
                }
            }
        }

        &__button-wrapper {
            margin-top: 35px;
            width: 100%;
            text-align: center;
        }

        &__col {
            padding-top: 80px;
            padding-bottom: 80px;

            @include max-width($scr-md) {
                padding-top: 40px;
                padding-bottom: 40px;
            }

            &--payment {
                display: flex;
                align-items: center;
                flex-direction: column;
                background-color: $c-body;
            }

            &--background {
                min-height: 800px;
                position: relative;
                background-color: $c-brand-cyan;
                background-size: 60%;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(../../../images/switch/logo.svg);

                @include max-width($scr-md) {
                    display: none;
                }

                &::before {
                    position: absolute;
                    content: "";
                    width: 50px;
                    height: 50px;
                    left: 0;
                    top: 10%;
                    background-color: $c-brand-navy;
                    display: block;
                }

                &::after {
                    position: absolute;
                    content: "";
                    width: 50%;
                    height: 50px;
                    right: 0;
                    bottom: 25%;
                    background-color: $c-brand-yellow;
                    display: block;
                }
            }
        }

        &__disclaimer {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            text-align: center;
            color: $c-white;
            font-size: $text-size-xs;
            font-weight: $text-weight-thin;
        }
    }
}
